import React, { useState } from 'react'
import {
    Select,
    Form,
    Switch,
    Tooltip,
    Button
} from 'antd'

import { 
    QuestionCircleOutlined,
    DownloadOutlined
} from '@ant-design/icons'
import { useMobile } from '../../Utils'

import axios from 'axios'
import { serverUrl } from '../../Constants'
import { useSelector } from 'react-redux'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportCatalogProducts() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const isMobile = useMobile()

    const { permissions } = useSelector((state: any) => state)

    const { Option } = Select

    /**
     * @todo Get from API.
     */
    const brandings = [
        { value: '4711', label: '4711' },
        { value: 'ABERCROMBIE', label: 'ABERCROMBIE' },
        { value: 'ALTERNA', label: 'ALTERNA' },
        { value: 'BALMAIN', label: 'BALMAIN' },
        { value: 'BANANA REPUBLIC', label: 'BANANA REPUBLIC' },
        { value: 'BEBE', label: 'BEBE' },
        { value: 'BENTLEY', label: 'BENTLEY' },
        { value: 'BOUCHERON', label: 'BOUCHERON' },
        { value: 'BRIONI', label: 'BRIONI' },
        { value: 'BROOKS BROTHERS', label: 'BROOKS BROTHERS' },
        { value: 'CARTIER', label: 'CARTIER' },
        { value: 'CHANEL', label: 'CHANEL' },
        { value: 'CLARINS', label: 'CLARINS' },
        { value: 'COACH', label: 'COACH' },
        { value: 'DESIGUAL', label: 'DESIGUAL' },
        { value: 'DUNHILL', label: 'DUNHILL' },
        { value: 'DUPONT', label: 'DUPONT' },
        { value: 'EL GANSO', label: 'EL GANSO' },
        { value: 'FERRAGAMO', label: 'FERRAGAMO' },
        { value: 'GAP', label: 'GAP' },
        { value: 'HALLOWEEN', label: 'HALLOWEEN' },
        { value: 'HEAD', label: 'HEAD' },
        { value: 'HERMES', label: 'HERMES' },
        { value: 'HOLLISTER', label: 'HOLLISTER' },
        { value: 'ING', label: 'ING' },
        { value: 'JAGUAR', label: 'JAGUAR' },
        { value: 'JIMMY CHOO', label: 'JIMMY CHOO' },
        { value: 'KARL', label: 'KARL' },
        { value: 'KATE SPADE', label: 'KATE SPADE' },
        { value: 'KATESPADE', label: 'KATESPADE' },
        { value: 'LA PRAIRIE', label: 'LA PRAIRIE' },
        { value: 'LACOSTE', label: 'LACOSTE' },
        { value: 'LALIQUE', label: 'LALIQUE' },
        { value: 'LANVIN', label: 'LANVIN' },
        { value: 'LUCATMI', label: 'LUCATMI' },
        { value: 'LUXIE', label: 'LUXIE' },
        { value: 'MANDARINA DUCK', label: 'MANDARINA DUCK' },
        { value: 'MAVALA', label: 'MAVALA' },
        { value: 'MOLYNEUX', label: 'MOLYNEUX' },
        { value: 'MONCLER', label: 'MONCLER' },
        { value: 'MONTBLANC', label: 'MONTBLANC' },
        { value: 'NINE WEST', label: 'NINE WEST' },
        { value: 'NINE', label: 'NINE' },
        { value: 'NUDESTIX', label: 'NUDESTIX' },
        { value: 'NUGGELA SULE', label: 'NUGGELA SULE' },
        { value: 'OSCAR DE LA RENTA', label: 'OSCAR DE LA RENTA' },
        { value: 'PARIS HILTON', label: 'PARIS HILTON' },
        { value: 'REPETTO', label: 'REPETTO' },
        { value: 'ROCHAS', label: 'ROCHAS' },
        { value: 'SCALPERS', label: 'SCALPERS' },
        { value: 'TABAC', label: 'TABAC' },
        { value: 'TOUS', label: 'TOUS' },
        { value: 'TRUSSARDI', label: 'TRUSSARDI' },
        { value: 'UNGARO', label: 'UNGARO' },
        { value: 'VAN CLEEF', label: 'VAN CLEEF' },
    ]

    const onFinish = (data: any) => {
        const defaultData = {
            showCostos: false,
            ...data
        }
        setLoading(true)

        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/catalog`,
            responseType: 'blob',
            data: defaultData
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Catalogo de Productos.xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
              window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + " Catalogo de Productos.xlsx");
            }
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        filterMarcas: [],
        onlyEan: false,
        simplified: true,
        showCostos: false
    }}>
        <p>El presente informe contiene el detalle en tiempo real de los productos registrados en tu bodega.</p>
        <br />
        <Form.Item name="filterMarcas" label="Marcas">
            <Select
                mode="multiple"
                allowClear
                placeholder={<><strong>Por defecto</strong>, descarga <strong>todas las marcas</strong> disponibles...</>}
                disabled={loading}
            >
            {brandings.map(brand => (
                <Option value={brand.value} key={brand.value}>{brand.label}</Option>
            ))}
            </Select>
        </Form.Item>
        <Form.Item name="onlyEan" label={<>Solo productos con EAN{!isMobile && <>&nbsp;
            <Tooltip placement="right" title="Incluye en el informe solamente los productos que tengan código de barras en el sistema.">
                <QuestionCircleOutlined />
            </Tooltip></>}</>}>
            <Switch loading={loading} />
        </Form.Item>
        <Form.Item name="simplified" label={<>Informe simplificado{!isMobile && <>&nbsp;
            <Tooltip placement="right" title={<><strong>Incluye solamente las columnas de </strong>Código interno, Descripción del producto, Marca y Código de Barras</>}>
                <QuestionCircleOutlined />
            </Tooltip></>}</>}>
            <Switch loading={loading} defaultChecked={true} />
        </Form.Item>
        {!permissions.includes('Comercial') && <Form.Item name="showCostos" label="Mostrar costos actualizados">
            <Switch loading={loading} />
        </Form.Item>}
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Descargar Informe
        </Button>
    </Form>
}
