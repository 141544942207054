import React, { useEffect, useState } from 'react'
import {
    Select,
    Form,
    Button,
    InputNumber,
    message
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { serverUrl } from '../../Constants'
import { useApi } from '../../Utils'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportCorrelatives() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { data, loading: _loading } = useApi<any>('get', 'v3/gestion/documentTypes')

    const [documentTypes, setDocumentTypes] = useState<string[]>([])

    useEffect(() => {
        if (data?.data) {
            setDocumentTypes(data.data)
        }
    }, [data])

    useEffect(() => {
        setLoading(_loading)
    }, [_loading])

    const { Option } = Select

    const onFinish = (data: any) => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/correlatives`,
            responseType: 'blob',
            data
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            // @ts-ignore
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Folios faltantes de '+ data.documentType + ' (' + data.year + ').xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' Folios faltantes de '+ data.documentType + ' (' + data.periodo + ').xlsx');
            }
            setLoading(false)
          }).catch((err) => {
            message.error('Ha ocurrido un error al descargar el reporte, intente nuevamente.')
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        year: `${new Date().getFullYear()}`
        // year: `${new Date().getFullYear()}${pad(new Date().getMonth() + 1, 2)}`
    }}>
        <p>Para procesos de fiscalización del SII o cuadraturas internas, puedes solicitar un registro de rangos de documentos emitidos. Este informe recibirá el correlativo final que indiques y a partir de ello, revisará todos los documentos que no sigan la correlatividad correspondiente.</p>
        <br />
        <Form.Item name="year" label="Año comercial" required rules={[
            { required: true, message: 'Debes seleccionar un año para la validación.' }
        ]}>
            <Select
                disabled={loading}
                showSearch
                allowClear
                placeholder='Selecciona un año comercial para la búsqueda'
            >
                <Option value={"2019"} key={"2019"}>{"2019"}</Option>
                <Option value={"2020"} key={"2020"}>{"2020"}</Option>
                <Option value={"2021"} key={"2021"}>{"2021"}</Option>
                <Option value={"2022"} key={"2022"}>{"2022"}</Option>
                <Option value={"2023"} key={"2023"}>{"2023"}</Option>
            </Select>
        </Form.Item>
        <Form.Item name="documentType" label="Tipo de documento" required rules={[
            { required: true, message: 'Debes seleccionar un tipo de documento' }
        ]}>
            <Select
                disabled={loading}
                showSearch
                allowClear
                placeholder='Selecciona un tipo de documento'
            >
                {documentTypes.map(field => (
                    <Option value={field} key={field}>{field}</Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item name="startAt" label="Correlativo inicial" rules={[
            { type: 'number', message: 'Debes ingresar un número' }
        ]}>
            <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="endsAt" label="Correlativo final" required rules={[
            { required: true, message: 'Debes ingresar un correlativo final' },
            { type: 'number', message: 'Debes ingresar un número' }
        ]}>
            <InputNumber min={0} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Solicitar Informe
        </Button>
    </Form>
}
