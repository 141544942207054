import React, { useEffect, useState } from 'react'
import {
    Select,
    Form,
    Button,
    message
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { serverUrl } from '../../Constants'
import { useApi } from '../../Utils'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportInventoryProcess() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { data, loading: _loading } = useApi<any>('get', 'v3/gestion/processInventories')

    const [availableProcess, setAvailableProcess] = useState<any[]>([])

    useEffect(() => {
        if (data?.data) {
            setAvailableProcess(data.data)
        }
    }, [data])

    useEffect(() => {
        setLoading(_loading)
    }, [_loading])

    const { Option } = Select

    const onFinish = (data: any) => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/processInventories`,
            responseType: 'blob',
            data
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            // @ts-ignore
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Toma Inventario '+ data.documentType + '.xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' Toma Inventario '+ data.documentType + '.xlsx');
            }
            setLoading(false)
          }).catch((err) => {
            message.error('Ha ocurrido un error al descargar el reporte, intente nuevamente.')
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        year: `${new Date().getFullYear()}`
        // year: `${new Date().getFullYear()}${pad(new Date().getMonth() + 1, 2)}`
    }}>
        <p>Para la descarga de tomas de inventario realizadas en Manager, agrega los documentos a continuación.</p>
        <br />
        <Form.Item name="documentType" label="Tomas de Inventario" required rules={[
            { required: true, message: 'Debes seleccionar al menos un documento.' }
        ]}>
            <Select
                disabled={loading}
                showSearch
                allowClear
                mode="multiple"
                placeholder='Selecciona las tomas'
            >
                {availableProcess.map(field => (
                    <Option value={field} key={field}>{field}</Option>
                ))}
            </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Solicitar Informe
        </Button>
    </Form>
}
