import React, { useEffect, useState } from 'react'

import { 
    SnippetsOutlined,
    OrderedListOutlined,
    BarcodeOutlined,
    CodeSandboxOutlined,
    MoneyCollectOutlined,
    CalendarOutlined,
    PullRequestOutlined,
    CloudServerOutlined,
    DropboxOutlined
} from '@ant-design/icons';

import {
    List,
    Row,
    Col,
    Empty,
    PageHeader,
    Badge
} from 'antd';

import './Accounting.scss';

import ReportCatalogProducts from './ReportCatalogProducts';
import ReportFolderExchange from './ReportFolderExchange';
import ReportCostOfSale from './ReportCostOfSale';
import ReportBookSellOut from './ReportBookSellOut';
import ReportPendingInvoice from './ReportPendingInvoice';
import ReportPosQuadrature from './ReportPosQuadrature';
import ReportCorrelatives from './ReportCorrelatives';
import { useSelector } from 'react-redux';
import ReportInventoryProcess from './ReportInventoryProcess';

function Accounting({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { permissions } = useSelector((state: any) => state)
    const [active, setActive] = useState<number>(0)

    const data = [
        {
            id: 1,
            title: 'Catálogo de Productos',
            description: 'Contiene los códigos registrados en el sistema, filtrados por Marca, código de barras y costos.',
            icon: <BarcodeOutlined style={{ fontSize: '32px'}} />,
            state: useState(false)
        },
        {
            id: 8,
            title: <Badge.Ribbon text='nuevo' color='red' placement='end' className='newBadge'>Tomas de Inventario</Badge.Ribbon>,
            description: 'Descarga de movimientos de toma de inventario de Flexline ERP.',
            icon: <DropboxOutlined style={{ fontSize: '32px' }} />,
            state: useState(false)
        },
    ]

    const fullData = [{
        id: 2,
        title: 'Carpetas de Importación',
        description: 'Reporte sobre el detalle de las carpetas de importación ingresadas, recepcionadas y valorizadas.',
        icon: <CodeSandboxOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    {
        id: 3,
        title: 'Costo de Venta',
        description: 'Información de costo de ventas en un periodo determinado.',
        icon: <MoneyCollectOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    {
        id: 4,
        title: 'Libro de Compras',
        description: 'Refleja el detalle de las compras registradas en un periodo determinado.',
        icon: <CalendarOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    {
        id: 5,
        title: 'Documentos sin Facturar',
        description: 'Informe sobre todos los documentos pendientes de facturar.',
        icon: <PullRequestOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    {
        id: 7,
        title: 'Correlatividad de Documentos',
        description: 'Informe sobre la correlatividad de los documentos.',
        icon: <OrderedListOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    {
        id: 6,
        title: 'Cuadratura POS',
        description: 'Cuadratura de ventas realizadas en el módulo de Punto de Ventas electrónico.',
        icon: <CloudServerOutlined style={{ fontSize: '32px' }} />,
        state: useState(false)
    },
    ]

    const resetAll = () => {
        data.map(item => item.state[1](false) )
        setActive(0)
    }
    
    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Informes', icon: <SnippetsOutlined /> },
                { key: 'emission', name: 'Facturación mensual', icon: <MoneyCollectOutlined /> },
                // { key: 'programming', name: 'Reportes Programados', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    const reportFrame = new Map<number, JSX.Element>([
        [0, <Empty description={<>Selecciona en el <strong>menú izquierdo</strong><br /> un reporte para comenzar.</>} />],
        [1, <ReportCatalogProducts />],
        [2, <ReportFolderExchange />],
        [3, <ReportCostOfSale />],
        [4, <ReportBookSellOut />],
        [5, <ReportPendingInvoice />],
        [6, <ReportPosQuadrature />],
        [7, <ReportCorrelatives />],
        [8, <ReportInventoryProcess />],
    ])

    const back = !active ? {} : {
        onBack: () => {
            resetAll()            
        }
    }

    useEffect(() => {
        setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="App">
            <div className="Accounting">
                    <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        {...back}
                        title={!active ? "Informes" : "Volver al listado de Informes"}
                        subTitle={!active ? "Reportes a medida" : ""}
                    />
                <div className="Wrapper">
                    <Row>
                        {(!isMobile || (isMobile && !active)) && <Col xs={24} md={8}>
                            <List
                                itemLayout="horizontal"
                                dataSource={[
                                    ...data,
                                    ...(permissions.includes('Comercial') ? [] : fullData)
                                ]}
                                renderItem={item => {
                                    const [selected, setSelected] = item.state
                                    return <List.Item className={`${selected ? 'hovered' : !active ? '' : 'wohovered'}`} onClick={() => { 
                                        resetAll()
                                        setActive(selected ? 0 : item.id)
                                        setSelected(!selected)
                                    }}>
                                        <List.Item.Meta
                                            avatar={item.icon}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </List.Item>
                                }}
                            />
                        </Col>}
                        {(!isMobile || (isMobile && active > 0)) && <Col xs={24} md={16} className="Accounting--ReportFrame">
                            {reportFrame.get(active)}
                        </Col>}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Accounting